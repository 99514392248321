* {
    scroll-behavior: smooth;
}

:root {
    --red: #FF5050;
    --grey: #868686;
    --light-white: #F6F6F6;
    --footer-dark: #282933;
    --white: #ffffff;
}

body {
    background-color: #FAFAFA;
}

.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}